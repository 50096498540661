<template>
    <div id="app">
        <rxNavBar title="文件查看"></rxNavBar>

        <div v-for="item,index in numPages" :key="index">
          <pdf
                :src="srcPageTo"
                ref="pdf"
                :page="index + 1"
            ></pdf>
        </div>
         
    </div>
</template>

<script>
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    // import PdfViewer from './PdfViewer.vue'

    import pdfjsLib from 'pdfjs-dist';
    import pdf from 'vue-pdf'
    export default {
        components: {
            rxNavBar,
            pdf,
            // PdfViewer
        },
        data() {
            return {
              srcPageTo: '', 
              url:'',
              numPages:''
            };
        },
        beforeCreate() {
        },
        
        created() {
           
        },
        mounted() {
          if(this.$route.query?.data) {
            let routerData = this.$route.query.data
            this.srcPageTo = routerData
            // console.log(routerData,"routerData=====")

          }
           
          // window.open(this.srcPageTo)
          this.getNumPages()
        },
        methods: {
         getNumPages() {
            let loadingTask = pdf.createLoadingTask(this.srcPageTo)
            loadingTask.promise.then(pdf => {
              this.numPages = pdf.numPages
            }).catch(err => {
              console.error('pdf 加载失败', err);
            })
          },
   
    
    

          

  
         }
}
</script>

<style lang="less" scoped>

</style>
<style scopde>
</style>
